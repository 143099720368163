/*
To customize the look and feel of Ionic, you can override the variables
in ionic's _variables.scss file.

For example, you might change some of the default colors:
*/

$light:                           #fff !default;
$stable:                          #f8f8f8 !default;
$positive:                        #4a87ee !default;
$calm:                            #0ab1ff !default;
$calmDarker:                      #09B0FF !default;
$balanced:                        #66cc33 !default;
$energized:                       #ffd342 !default;
$assertive:                       #ef4e3a !default;
$royal:                           #8a6de9 !default;
$dark:                            #444 !default;
$blue:                            #0ab1ff !default;
$green:                            #34d99a !default;
$orange:                            #fcbd3f !default;
$yellow:                            #fcd251 !default;
$red:                            #fc8d74 !default;
$ios-transition-container-bg-color: transparent;

// The path for our ionicons font files, relative to the built & temporary main.css
$ionicons-font-path: "../assets/fonts" !default;

// Include all of Ionic
@import "../../bower_components/ionic/scss/ionic";

.flex-calendar .month {
  background-color: $calm;
}
.flex-calendar .week {
  background-color: $calm;
}
.flex-calendar .days .day.selected .number {
  width: 40px;
  height: 40px;
  background-color: $calm;
  border-radius: 100%;
  line-height: 40px;
  color: #FFFFFF;
}




.calendar-container {
  margin-top:20px;
}

.rating-level-container {
  display:flex;
  flex-direction:row;
  align-self:stretch;
  margin-bottom:5px;
  .rating-level-stars {
    flex: 0 0 auto;
    .stars li i {
      font-size: 15px !important;
      color:$calm !important;
    }
  }
  .rating-level-progress-container {
    flex:1 0 auto;
    border:1px #cccccc solid;
    border-radius:3px;
    margin-left:5px;
    margin-right:5px;

    .five-star-progress {
      background:$blue;
      min-width:5px;
    }
    .four-star-progress {
      background:$green;
      min-width:5px;
    }
    .three-star-progress {
      background:$orange;
      min-width:5px;
    }
    .two-star-progress {
      background:$yellow;
      min-width:5px;
    }
    .one-star-progress {
      background:$red;
      min-width:5px;
    }
  }
  .rating-level-amount {
    flex:0 0 auto;
    min-width:20px;
    text-align:center;
  }
}

.reports-left-container {
  background:#F3F3F3;
  min-width:400px;
  flex:0 0 150;
  border-right:#CCCCCC 1px solid;
  display:none;
  align-content:center;
  align-items:center;
  flex-direction:column;
  padding:30px;
  @media screen and (min-width: 520px) {
    display: flex;
  }
  .header {
    font-size:30px;
  }

  .average-container {
    margin-top:35px;
    display:flex;
    flex-direction:column;
    background:$calm;
    border-radius:50%;
    height:150px;
    width:150px;
    align-items:center;
    justify-content:center;
    align-content:center;
    .average-score {
      font-size:50px;
      font-weight:normal;
      color:#ffffff;
    }

    .average-label {
      text-transform:uppercase;
      margin-top:15px;
      opacity:0.8;
    }
  }
}

.reports-right-container {
  flex:1;
  display:flex;
  flex-direction:column;
}

.reports-top-container {
  display:flex;
  flex-direction:row;
  flex:0 0 120;
  min-height:100px;
  align-items:center;
  .stat-container {
    align-items:center;
    align-content:center;
    justify-content:center;
    display:flex;
    flex-direction:column;
    padding:20px;
    text-align:center;

    .stat-value {
      align-items:center;
      align-content:center;
      justify-content:center;
      font-size:40px;
      color:#000000;
    }
    .stat-label {
      align-items:center;
      align-content:center;
      justify-content:center;
      color:#000000;
      text-transform:uppercase;
      margin-top:10px;
      opacity:0.8;
    }
  }
}

.reports-main-container {
  background:#ffffff;
  flex:1 1 auto;
}

.reports-container {
  .reports-scroll-view {
    overflow-y: scroll;
    height:100vh;
  }
}

.reports-item-container {
  padding:0px;
  display:flex;
  flex-direction:column;

  ion-item.item {
    padding:0px;
  }

  .receipt-container {
    display:flex;
    flex-direction:row;
  }

  .receipt-actions {
    min-width:200px;
    padding:16px;
    padding-top:0px;
    padding-left:0px;
  }

  .receipt {
    border:1px #cccccc solid;
    padding:16px;
    margin:16px;
    flex:1 1 auto;
    display:flex;
    flex-direction:column;
    align-items:center;
    align-content:center;
    justify-content:center;
    background:#f3f3f3;

    .item {
      background:transparent;
    }
    .list {
      background:transparent;
    }
  }

  .item-content-container {
    padding-top:25px;
    padding-bottom:10px;
    padding-left:20px;
    padding-right:20px;
    display:flex;
    flex-direction:row;

    .item-content-time {
      padding-left:10px;
      padding-right:10px;
      flex:0 0 auto;
    }
    .item-content-amount {
      padding-left:10px;
      padding-right:10px;
      flex:0 0 auto;
    }
    .item-content-name {
      padding-left:10px;
      padding-right:10px;
      flex:1 0 auto;
    }
    .item-content-tablenumber {
      padding-left:10px;
      padding-right:10px;
      flex:0 0 auto;
    }
    .item-content-card {
      padding-left:10px;
      padding-right:16px;
      flex:0 0 auto;
    }
    .item-content-receipt {
      display:flex;
      flex-direction:row;
    }
  }
}

.reviews-left-container {
  background:#F3F3F3;
  min-width:300px;
  flex:0 0 150;
  border-right:#CCCCCC 1px solid;
  display:none;
  align-content:center;
  align-items:center;
  flex-direction:column;
  padding:30px;
  @media screen and (min-width: 520px) {
    display: flex;
  }

  .header {
    font-size:30px;
  }

  .average-container {
    margin-top:35px;
    display:flex;
    flex-direction:column;
    background:$calm;
    border-radius:50%;
    height:150px;
    width:150px;
    align-items:center;
    justify-content:center;
    align-content:center;
    .average-score {
      font-size:50px;
      font-weight:normal;
      color:#ffffff;
    }

    .average-label {
      text-transform:uppercase;
      margin-top:15px;
      opacity:0.8;
    }
  }
}

.reviews-right-container {
  flex:1;
  display:flex;
  flex-direction:column;
}

.reviews-top-container {
  display:flex;
  flex-direction:row;
  flex:0 0 120;
  background:$calm;
  min-height:100px;
  align-items:center;
  padding-left:40px;
  .stat-container {
    padding:20px;
    text-align:center;
    margin-right:100px;
    .stat-value {
      font-size:40px;
      color:#ffffff;
    }
    .stat-label {
      text-transform:uppercase;
      margin-top:10px;
      opacity:0.8;
    }
  }
}

.reviews-main-container {
  background:#ffffff;
  flex:1 1 auto;
}

.review-container {
  .review-scroll-view {
    overflow-y: scroll;
    height:100vh;
  }
}

.review-item-container {
  padding:0px;

  ion-item.item {
    padding:0px;
  }

  .content-container {
    border-bottom:3px #CCCCCC solid;
    padding-top:25px;
    padding-bottom:10px;
    padding-left:20px;
    padding-right:20px;
    display:flex;
    flex-direction:column;
    .content-header {
      display:flex;
      flex-direction:row;

      .review-name {
        flex:0 0 auto;
        font-weight:bold;
        font-size:18px;
      }
      .review-date {
        flex:0 0 auto;
        padding-left:16px;
        font-size:12px;
      }
      .review-stars {
        flex:1 0 auto;
        padding-left:16px;
        .stars li i {
          font-size: 20px !important;
          color:$calm !important;
        }
      }
      .review-actions {
        flex:0 0 auto;
      }
    }

    .content-body {
      padding-top:10px;
      padding-bottom:16px;
    }
  }
}

ul.rating li {
  display: inline;
  border: 0px;
  padding-left:3px;
  background: none;
}
ul.rating li i {
  font-size: 30px;
  color:#ffffff;
}

ul.rating {
}

.yellowstars li i {
  font-size: 30px !important;
  color:$energized !important;
}

.scroll-view.scroll-y {
    overflow-y: hidden;  /* default value is 'scroll' */
}

.scroll-content {
  padding-left:0px !important;
  padding-right:0px !important;
  padding-top:0px !important;
}

.item-select select {
  padding:0px !important;
  max-width:100px !important;
  position:relative;
}

.item {
  border-width:0px !important;
  margin-left:0px;
}

.item-select:after {
    display:none;
}

.gallery {
  display: flex;
	display: -webkit-flex;
	-webkit-flex: 1;
	flex: 1;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-justify-content: left;
	justify-content: left;
	-webkit-align-items: flex-start;
	align-items: flex-start;
	margin-top: 1em;
}

.gallery-item {
  margin-bottom: 1em;
	min-width: 275px;
	-webkit-flex: 1 1 275;
	flex: 1 1 275;
}

.table-header-idle {
  background:#F3F3F3;
}

.table-header-open {
  background:#23db9b;
}

.table-header-ready {
  background:#ffd342;
  border-bottom:0px;
}

.table-body-idle {
  background:#ffffff;
}

.table-body-open {
  background:#defaf0;
}

.table-body-ready {
  background:#ffd342;
}

.table-body-checkedin {
  background:#23db9b;
}

.table-body-allpaid {
  background:#23db9b;
  border-top:#1f875f 2px solid;
}

.black-text {
  color:#000000 !important;
}

.blue-background {
  background:$calm;
}

.spinner svg {
  width: 50px;
  height: 50px;
}

.selected-tab {
  background:#ffffff;
  padding-left:16px;
  padding-right:16px;
  border-top:5px $calm solid;
}

.unselected-tab {
  background:transparent;
  padding-left:16px;
  padding-right:16px;
  color:#ffffff;
}

.disabled-tab {

}

.modal-table-number {
  color:#00FF00;
  font-weight:800;
  padding-left:16px;
  padding-right:16px;
  font-size:14px;
}

.ready-text-input {
  padding-left:8px !important;
  height:45px !important;
  border:1px #cccccc solid !important;
  background:#ffffff !important;
  color:#000000 !important;
  border-radius:5px;
  margin-left:8px;
  margin-top:1px;
  -webkit-appearance: none;
  padding-top:4px;
}

@font-face {
  font-family: 'icomoon';
  src:  url('../assets/fonts/icomoon.eot?uvjfij');
  src:  url('../assets/fonts/icomoon.eot?uvjfij#iefix') format('embedded-opentype'),
    url('../assets/fonts/icomoon.ttf?uvjfij') format('truetype'),
    url('../assets/fonts/icomoon.woff?uvjfij') format('woff'),
    url('../assets/fonts/icomoon.svg?uvjfij#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icon-camera:before {
  content: "\e900";
}
.icon-icon-cc:before {
  content: "\e901";
}
.icon-icon-check:before {
  content: "\e902";
}
.icon-icon-close:before {
  content: "\e903";
}
.icon-icon-gear:before {
  content: "\e905";
}
.icon-icon-key:before {
  content: "\e906";
}
.icon-icon-lock:before {
  content: "\e907";
}
.icon-icon-mail:before {
  content: "\e908";
}
.icon-icon-mobile:before {
  content: "\e909";
}
.icon-icon-profile:before {
  content: "\e90a";
}
.icon-icon-receipt:before {
  content: "\e90b";
}
.icon-icon-scan-card:before {
  content: "\e90c";
}
.icon-icon-scan-qrcode:before {
  content: "\e90d";
}
.icon-icon-split:before {
  content: "\e90e";
}
.icon-icon-star:before {
  content: "\e910";
}
.icon-icon-unlocked:before {
  content: "\e911";
}
.icon-icon-up-arrow:before {
  content: "\e912";
}

body, div {
  font-family: 'Source Sans Pro', sans-serif;
}

@keyframes shake {
  0% {transform: translateX(0);}
  12.5% {transform: translateX(-6px) rotateY(-5deg)}
  37.5% {transform: translateX(5px) rotateY(4deg)}
  62.5% {transform: translateX(-3px) rotateY(-2deg)}
  87.5% {transform: translateX(2px) rotateY(1deg)}
  100% {transform: translateX(0)}
}

@-webkit-keyframes shake {
  0% {-webkit-transform: translateX(0);}
  12.5% {-webkit-transform: translateX(-6px) rotateY(-5deg)}
  37.5% {-webkit-transform: translateX(5px) rotateY(4deg)}
  62.5% {-webkit-transform: translateX(-3px) rotateY(-2deg)}
  87.5% {-webkit-transform: translateX(2px) rotateY(1deg)}
  100% {-webkit-transform: translateX(0)}
}

.shake {
  -webkit-animation: shake 400ms ease-in-out;
  animation: shake 400ms ease-in-out;
}

select.form-control {
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow:none;
  display:inline;
  transition:none;
  -webkit-transition:none;
}

.centered {
  display:flex;
  align-items:center;
  align-content:center;
  justify-content:center;
  text-align:center;
}

.ionic_ratings .icon {
  font-size:40px;
}

.profile-name-entry {
  padding-top:16px;
}
input[type="text"].profile-name-textbox {
  text-align:center;
  color:#FFFFFF;
  font-size:22px;
  background:transparent;

}

input[type="text"].profile-name-textbox::-webkit-input-placeholder {
  color: #ffffff;
}
input[type="text"].profile-name-textbox:-moz-placeholder {
  color: #ffffff;
}
input[type="text"].profile-name-textbox::-moz-placeholder {
  color: #ffffff;
}
input[type="text"].profile-name-textbox:-ms-input-placeholder {
  color: #ffffff;
}

input[type="text"].transparent-textbox-centered::-webkit-input-placeholder {
  color: #ffffff;
}
input[type="text"].transparent-textbox-centered:-moz-placeholder {
  color: #ffffff;
}
input[type="text"].transparent-textbox-centered::-moz-placeholder {
  color: #ffffff;
}
input[type="text"].transparent-textbox-centered:-ms-input-placeholder {
  color: #ffffff;
}

input[type="text"].transparent-textbox-centered {
  background:transparent;
  text-align:center;
  width:100%;
  color:#ffffff;
  font-size:20px;
}
input[type="email"].transparent-textbox-centered {
  background:transparent;
  text-align:center;
  width:100%;
  color:#ffffff;
  font-size:20px;
}

input.transparent-textbox-centered {
  color:#ffffff;
}

.blue-area {
  background:$calm;
  display:flex;
  align-items:center;
  align-content:center;
  flex-direction:column;
  padding:20px;
  padding-top:25px;
}

.profile-container {
  padding:0px;
  margin:0px;
  width:100%;
  height:100%;
  display:flex;
  flex-direction:column;
}

.profile-header {
  background:$calm;
  height:200px;
  width:100%;
  display:flex;
  align-items:center;
  align-content:center;
  justify-content:center;
  flex-direction:column;
  padding:20px;
  flex: 0 0 auto;
}

.profile-tabs-container {
  height:60px;
  width:100%;
  display:flex;
  flex-direction:row;
  flex-grow:0;
  flex:0 0 auto;
  background:$calm;

}

.profile-content {
  background:#ffffff;
  padding:20px;
}

.profile-list-content {
  background:#ffffff;
  flex-grow: 1 1 300;
}

.profile-tab-selected {
  height:55px;
  background:#ffffff;
  display:flex;
  align-items:center;
  align-content:center;
  justify-content:center;
  flex-grow:1;
  margin-top:5px;
}

.profile-tab {
  height:60px;
  background:#eeeeee;
  display:flex;
  align-items:center;
  align-content:center;
  justify-content:center;
  border-bottom:2px #CCCCCC solid;
  flex-grow:1;
}

.profile-photo {
  border-radius:50%;
  width:100px;
  height:100px;
  background:#ffffff;
  background-size:cover;
  display:flex;
  align-items:center;
  align-content:center;
  justify-content:center;
  border:3px #ffffff solid;
}

.payment-item-container {
  display:flex;
  flex-direction:row;
}

.payment-item-details {
  flex-grow:1;
}

.payment-item-actions {
  flex-grow:0;
  display:flex;
  align-items:center;
  align-content:center;
  justify-content:center;
}

.receipt-item-container {
  display:flex;
  flex-direction:column;
}

.receipt-item-title {

}

.receipt-item-subtitle {
  color:#cccccc;
}

.full-content-holder {
  display:flex;
  flex-direction:column;
  height:100%;
  .full-content {
    padding:20px;
    flex-grow:0;
    display:flex;
    flex-direction: column;
    align-items:center;
    align-content:center;
    justify-content: center;
    background:#ffffff;
  }
  .full-footer {
    padding:20px;
    flex-grow:1;
    background:#1cb7ff;
  }
  .full-footer-white {
    padding:20px;
    flex-grow:1;
    background:#ffffff;
  }
}

.padding-16-top {
  padding-top:16px;
}

.email {
  width:100%;
}

.modal-backdrop {
  background:transparent;
}

.row {
  margin-left:0px;
  margin-right:0px;
}

.fade.ng-hide {
  transition:0.5s linear all;
  opacity:0;
}

.bar {
  height:60px;
}

.bar .title {
  top:10px;
}

.bar .button {
  top:5px;
}

.button-bold {
  font-family: 'Source Sans Pro', sans-serif;
  font-size:20px;
  font-weight:800;
  line-height:24px;
  height:50px;
  background:$calm;
  color:#FFFFFF;
}

.button-bold:hover {
  font-family: 'Source Sans Pro', sans-serif;
  font-size:20px;
  font-weight:800;
  line-height:24px;
  height:50px;
  background:$calm;
  color:#FFFFFF;
}

.ghost-button {
  font-family: 'Source Sans Pro', sans-serif;
  font-size:18px;
  line-height:22px;
  font-weight:600;
  height:50px;
  background:transparent;
  border:1px $calm solid;
  color:$calm;
}
.ghost-button:hover {
  font-family: 'Source Sans Pro', sans-serif;
  font-size:18px;
  line-height:22px;
  font-weight:600;
  height:50px;
  background:transparent;
  border:1px $calm solid;
  color:$calm;
}

.small-button {
  font-family: 'Source Sans Pro', sans-serif;
  font-size:16px;
  line-height:22px;
  height:36px;
  font-weight:800;
  background:$calm;
}

.white-ghost-button {
  font-family: 'Source Sans Pro', sans-serif;
  font-size:18px;
  line-height:22px;
  font-weight:600;
  height:50px;
  color:#FFFFFF;
  background:rgba(0,0,0,0.1);
  border:1px #ffffff solid;
}

.white-ghost-button:hover{
  font-family: 'Source Sans Pro', sans-serif;
  color:#FFFFFF;
  background:rgba(0,0,0,0.2);
  border:1px #ffffff solid;
}

.white-ghost-button:active{
  font-family: 'Source Sans Pro', sans-serif;
  color:#FFFFFF;
  background:rgba(0,0,0,0.2);
  border:1px #ffffff solid;
}

.super-button {
  font-family: 'Source Sans Pro', sans-serif;
  background:$energized;
  color:#000000;
  font-size:20px;
  line-height:24px;
  font-weight:800;
  height:50px;
}

.has-header {
  top:60px;
}

.platform-ios.platform-cordova:not(.fullscreen) .bar-header:not(.bar-subheader) {
  height:80px;
}

.platform-ios.platform-cordova:not(.fullscreen) .has-header, .platform-ios.platform-cordova:not(.fullscreen) .bar-subheader {
  top:80px;
}

.header-logo-container {
  height:100%;
  display:flex;
  align-items:center;
  align-content:center;
  justify-content:center;

  img {
    height:30px;
  }
}

.centered-content-container {
  height:100%;
  width:100%;
  display:flex;
  align-items:center;
  align-content:center;
  justify-content:center;
}

.helvetica-regular-22 {
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-weight:400;
  font-size:22px;
  line-height:26px;
}

.helvetica-bold-17 {
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-size:17px;
  line-height:20px;
  font-weight:800;
}

.heading3 {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight:700;
  font-size:22px;
  line-height:26px;
}
.heading4 {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight:600;
  font-size:22px;
  line-height:28px;
}

.heading5 {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight:700;
  font-size:18px;
  line-height:20px;
}

.heading6 {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight:700;
  font-size:17px;
  line-height:26px;
}

.heading7 {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight:700;
  font-size:20px;
  line-height:28px;
  color:white;
}

.largebody {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight:600;
  font-size:18px;
  line-height:26px;
}

.body {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight:600;
  font-size:16px;
  line-height:26px;
}

.smallbody {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight:400;
  font-size:14px;
  line-height:26px;
}

.largetext {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight:400;
  font-size:17px;
  line-height:26px;
}

.normaltext {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight:400;
  font-size:16px;
  line-height:22px;
}

.tinytext {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight:400;
  font-size:13px;
  line-height:16px;
}

.fineprint {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight:400;
  font-size:10px;
  line-height:13px;
}

.labelregular {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight:400;
  font-size:16px;
  line-height:20px;
}

.button-text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight:700;
  font-size:20px;
  line-height:24px;
}

.ghost-button-text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight:600;
  font-size:18px;
  line-height:22px;
}

.button.disabled, .button[disabled], .button-grey-disabled {
  color:#cccccc !important;
}

.blue-text {
  color:$calm;
}

.blue-text-darker {
  color:$calmDarker;
}

.deep-yellow {
  background:#FFC029;
}

.yellow {
  background:#FFD642;
}

.dark-blue {
  background:#0A396C;
}

#flexcanvas{
  width: 100%;
  height: 600px !important;
}

.rowParent, .columnParent{
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.columnParent{
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.flexChild{
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
}


#rowChild41241{
 -webkit-align-self: auto;
 -ms-flex-item-align: auto;
 align-self: auto; -webkit-box-flex: 0; -webkit-flex: 0 0 auto; -ms-flex: 0 0 auto; flex: 0 0 auto; width: 80px;
}


#container{
 -webkit-box-pack: end;
 -webkit-justify-content: flex-end;
 -ms-flex-pack: end;
 justify-content: flex-end; -webkit-box-align: stretch; -webkit-align-items: stretch; -ms-flex-align: stretch; align-items: stretch;
}

#columnChild2399{
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

#columnChild30202{
 -webkit-box-flex: 0;
 -webkit-flex: 0 0 auto;
 -ms-flex: 0 0 auto;
 flex: 0 0 auto;
}


#columnChild56374{
 -webkit-align-self: auto;
 -ms-flex-item-align: auto;
 align-self: auto;
 flex:1;
}


#rowChild3394{
 -webkit-box-align: stretch;
 -webkit-align-items: stretch;
 -ms-flex-align: stretch;
 align-items: stretch;
   overflow: hidden;

}
